// Function to check if Google Analytics is available
const tagManagerUrl = 'https://www.googletagmanager.com/gtag/js';
const analyticsUrl = 'https://www.google-analytics.com/analytics.js';

async function isGoogleAnalyticsAvailable() {
	try {
		const response = await fetch(tagManagerUrl, {
			method: 'HEAD',
			mode: 'no-cors',
		});
		console.debug('Google Analytics availability check response:', response);
		return response.type === 'opaque';
	} catch (error) {
		console.debug('Google Analytics availability check failed:', error);
		return false;
	}
}

// Load the Google Analytics script
function loadGoogleAnalyticsScript() {
	const script = document.createElement('script');
	script.async = true;
	script.src = `${tagManagerUrl}?id=AW-999806276`;
	script.onerror = () => {
		window.gtag = () => {
			console.debug('Google Analytics is not available, tracking skipped');
		};
	};
	document.head.appendChild(script);
}

// Function to safely initialize Google Analytics
async function initializeGoogleAnalytics() {
	const isAvailable = await isGoogleAnalyticsAvailable();
	console.debug(`Google Analytics availability check=${isAvailable}`);
	if (isAvailable) {
		window.dataLayer = window.dataLayer || [];
		window.gtag = () => {
			// biome-ignore lint/style/noArguments: <explanation>
			dataLayer.push(arguments);
		};
		gtag('js', new Date());

		// Initialize your GA measurement IDs
		gtag('config', 'AW-999806276');

		// gtag('config', 'G-F5VGY7TM7E');
		// gtag('config', 'G-8284R4S630');
		// gtag('config', 'G-7LVJ1QZBL0');
	} else {
		// Create a no-op gtag function to prevent errors
		window.gtag = () => {
			console.debug('Google Analytics is not available, tracking skipped');
		};
	}
}

// Initialize everything
loadGoogleAnalyticsScript();
initializeGoogleAnalytics();

console.debug('Google Analytics availability LOADED:');
